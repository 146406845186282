import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"

const ThankYou = ({ location }) => {
  return (
    <>
      <SEO
        titleOverride={"Thank You"}
        pathnameOverride={location.pathname}
      />

      <section className="mb-16 lg:mb-32">
        <div className="container">
          <div className="w-full mx-auto mb-10 text-center md:w-2/3 md:mb-16">
            <h1>Thank you for your referral</h1>
          </div>
        </div>
        <div className="container relative z-10">
          <div className="w-full mx-auto md:w-8/12 xl:w-1/2">
            <div className="content">
              <p>Thank you very much for your referral, we will be in touch with your contact and arrange a meeting with them.</p>
              <p>In the meantime, feel free to navigate <Link to="/client-referral/" className="underline">back</Link> and submit another referral.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ThankYou